<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'
import type { Rules } from '../types'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    label?: string
    infoText?: string
    hasBorder?: boolean
    rawValue?: boolean
    placeholder?: string
    name: string
    mask?: string | string[]
    modelValue?: string | number
    rules?: Rules
    wrapperClass?: string
    labelHtml?: string
    inputHtml?: string
  }>(),
  {
    disabled: false,
    label: '',
    infoText: '',
    mask: '',
    modelValue: '',
    placeholder: '',
    hasBorder: true,
    rawValue: false,
    rules: '',
    labelHtml: '',
    inputHtml: '',
    wrapperClass: 'w-full mb-4',
  },
)

const emits = defineEmits<{
  blur: []
  'raw-value': []
  'update:model-value': [string | undefined]
}>()
const rules = toRef(props, 'rules')

const {
  value: inputValue,
  handleBlur,
  handleChange,
  errors,
} = useField<string | number>(props.name, rules, {
  initialValue: props.modelValue,
})

const isError = computed(() => Boolean(errors.value.length))

function onBlur(): void {
  handleBlur()
  emits('blur')
}

function onChange(event: Event & { target: HTMLInputElement }): void {
  handleChange(event.target.value)

  if (props.rawValue) {
    emits('update:model-value', event.target.dataset.maskRawValue)
  } else {
    emits('update:model-value', event.target.value)
  }
}

const computedClass: ComputedRef<string[]> = computed(() => {
  const computedClass = ['base-mask-input']

  if (!props.hasBorder) {
    computedClass.push('base-mask-input--noBorder')
  }
  if (props.disabled) {
    computedClass.push('base-mask-input--disabled')
  }
  if (isError.value && props.hasBorder) {
    computedClass.push('base-mask-input--hasError-hasBorder')
  }
  if (!isError.value && props.hasBorder) {
    computedClass.push('base-mask-input--hasntError-hasBorder')
  }

  return computedClass
})
</script>

<template>
  <div :class="wrapperClass">
    <slot name="label">
      <label v-if="label" :for="name" class="flex">
        <BaseFormComponentsBaseFormLabel
          :label="label"
          :label-html="labelHtml"
        />
        <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
      </label>
    </slot>

    <client-only>
      <input
        :id="name"
        :ref="name"
        v-maska
        type="text"
        :data-maska="mask"
        :class="computedClass"
        :name="name"
        :placeholder="placeholder"
        :value="inputValue"
        data-testid="base-mask-input"
        v-bind="$attrs"
        @blur="onBlur"
        @maska="onChange"
      />
    </client-only>

    <BaseFormComponentsBaseStringToHtml
      v-if="inputHtml"
      :str-html="inputHtml"
    />

    <BaseFormComponentsBaseFieldInfo v-if="infoText" :text="infoText" />

    <error-message :name="name" as="span" class="base-form--error" />
  </div>
</template>

<style>
.base-mask-input {
  height: 51px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  @apply block bg-clip-padding w-full font-normal text-base leading-normal py-1.5 px-4 bg-white focus:text-gray-500 focus:bg-white focus:border-primary-100 focus:shadow-focus focus:outline-none border rounded-sm text-gray-700;
}
.base-mask-input--noBorder {
  @apply border-none;
}
.base-mask-input--disabled {
  @apply bg-gray-200 pointer-events-none text-gray-500;
}
.base-mask-input--hasError-hasBorder {
  @apply border-error;
}
.base-mask-input--hasntError-hasBorder {
  @apply border-gray-200;
}
.base-mask-input[disabled='disabled'] {
  -webkit-text-fill-color: #aaaaaa;
}
</style>
